.gradient {
  background: linear-gradient(
    to right,
    rgb(174, 243, 83) 25%,
    rgb(13, 194, 124) 75%
  );
  -webkit-background-clip: text;
  color: transparent;
}

/* rgb(12, 96, 206) 25%,
    rgb(18, 211, 163) 75% */
